import './App.css';
import pumpkinLogo from './assets/3_PumpkinToken.png';
import { FaTwitter, FaTelegram, FaFileAlt } from 'react-icons/fa';

export default function Component() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to Pumpkin Token!</h1>
        <img src={pumpkinLogo} className="App-logo" alt="Pumpkin Token" />
        <p>
          Join the revolution with Pumpkin Token—the spiciest meme coin on the market! 🚀🎃
        </p>
        <h2>The Heroic Tale of Pumpkin</h2>
        <p>
          Pumpkin the Cat, once a humble companion, saved a man's life. 
          When the man was struggling with addiction, Pumpkin scratched him, forcing him to the hospital. 
          There, doctors discovered life-threatening issues that they were able to address in time, all thanks to Pumpkin.
          Now, Pumpkin Token represents hope, courage, and redemption, just as Pumpkin did for that man.
        </p>

        {/* Container for Whitepaper, DexScreener and BlueMove Links */}
        <div className="top-links">
          <a
            className="App-link"
            href="https://thepumpkintoken.org/PumpkinWhitePaper1.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFileAlt /> Whitepaper
          </a>
          <a
            className="App-link"
            href="https://dexscreener.com/sui/0x3370693cdc29080a1e90f0f927198c751338de7ef7689b505865bf16a0a30461"
            target="_blank"
            rel="noopener noreferrer"
          >
            🎃 DexScreener Listing
          </a>
          <a
            className="App-link"
            href="https://dex.bluemove.net/swap/0x2::sui::SUI/0x9f1c8f05cb47bbcb61133dd2ef00583720694f41d4f8a61c94467d8f5911a14::pumpkin::PUMPKIN"
            target="_blank"
            rel="noopener noreferrer"
          >
            🎃 BlueMove Swap
          </a>
        </div>

        {/* Social Links */}
        <div className="social-links">
          <a
            className="App-link"
            href="https://x.com/thepumpkintoken"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter /> Follow us on X (Twitter)
          </a>

          <a
            className="App-link"
            href="https://t.me/+IvaAbB-kQyc3NGQx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram /> Join our Telegram
          </a>

          {/* Pumpkin Token Contract */}
          <div className="contract-links">
            <a
              className="App-link"
              href="https://movepump.com/token/0x09f1c8f05cb47bbcb61133dd2ef00583720694f41d4f8a61c94467d8f5911a14::pumpkin::PUMPKIN"
              target="_blank"
              rel="noopener noreferrer"
            >
              🎃 MovePump Contract
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}